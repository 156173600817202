import { Flex, Text } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'

interface DextChartProps {
  token0Address: string
  token1Address: string
  pairAddress: string
  isMobile: boolean
  isDark: boolean
}

const DextChart: React.FC<React.PropsWithChildren<DextChartProps>> = ({
  token0Address,
  token1Address,
  pairAddress,
  isMobile,
  isDark,
}) => {
  const { t } = useTranslation()
  console.log({ token0Address, token1Address, pairAddress, isDark })
  return (
    <>
      {pairAddress && (
        <iframe
          id="dextools-widget"
          title="DEXTools Trading Chart"
          width="100%"
          height="100%"
          src={`https://www.dextools.io/widget-chart/en/coredao/pe-light/${pairAddress}?theme=${
            isDark ? 'dark' : 'light'
          }&chartType=2&chartResolution=30&drawingToolbars=false`}
        ></iframe>
      )}
    </>
  )
}

export default DextChart
